// @import url('https://fonts.googleapis.com/css?family=Montserrat:400,500|Roboto+Slab');

$primary-text-color: #000;
//
$bg-color: #fff;
//
$modular-scale-base-px: 7;

h1, h2, h3, h4, h5, strong, em, p code,
h1 span, h2 span, h3 span, h4 span, h5 span {
  background: transparent;
  background-clip: initial;
  -webkit-text-fill-color: initial;
}


html {
  $base-font-size: $modular-scale-base-px * 2;
  font-size: #{$base-font-size}px;
}

@import 'includes/functions';

section {
  padding: to-rem(1*$modular-scale-base-px) 0 0 0;
}

nav, footer {
  display: none;
}

blockquote, iframe, ol, p, ul, hgroup {
  margin: 0 0 to-rem(1*$modular-scale-base-px);
}

hr {
  margin: to-rem(1*$modular-scale-base-px) 0;
  opacity: .5;
}

main {
  width: auto;
  max-width: none;
  margin: 0;
}

@page {
  margin: 1.5cm;
}

hgroup {
  page-break-inside: avoid;
}

h1, h2, h3 {
  page-break-after: never;
}

h1 {
  margin: 0 0 to-rem(4*$modular-scale-base-px) 0;
  font-size: to-rem(6*$modular-scale-base-px);
}

h2 {
  margin: to-rem(2*$modular-scale-base-px) 0;
  font-size: to-rem(4*$modular-scale-base-px);
}

h3 {
  margin: to-rem(2*$modular-scale-base-px) 0;
  font-weight: bolder;
  font-size: to-rem(3*$modular-scale-base-px);
}

h4 {
  margin: to-rem(2*$modular-scale-base-px) 0;
  font-weight: normal;
  font-style: italic;
  margin: 0;
  font-size: to-rem(3*$modular-scale-base-px);
}

h5 {
  font-weight: lighter;
  margin: 0;
  font-size: to-rem(3*$modular-scale-base-px);
}

p {
  margin: to-rem(2*$modular-scale-base-px) 0 to-rem(4*$modular-scale-base-px);
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: $primary-text-color;
}

hr {
  border-color: transparent;
}

.hide-print {
  display: none;
}

.page--index ul::after {
  content: "";
  flex: auto;
}

.page--index ul li {
  flex: 0 0 25% !important;
}

@import 'includes/prism-theme-print';
