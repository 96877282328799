h1, h2, h3, h4, strong, em, p code,
h1 span, h2 span, h3 span, h4 span {
  background: transparent;
  background-clip: initial;
  -webkit-text-fill-color: initial;
}

html {
  font-size: 14px;
}

section {
  padding: 0.4375rem 0 0 0;
}

nav, footer {
  display: none;
}

blockquote, iframe, ol, p, ul, hgroup {
  margin: 0 0 0.4375rem;
}

hr {
  margin: 0.4375rem 0;
  opacity: 0.5;
}

main {
  width: auto;
  max-width: none;
  margin: 0;
}

@page {
  margin: 1.5cm;
}
hgroup {
  page-break-inside: avoid;
}

h1, h2, h3 {
  page-break-after: never;
}

h1 {
  margin: 0 0 1.75rem 0;
  font-size: 2.625rem;
}

h2 {
  margin: 0.875rem 0;
  font-size: 1.75rem;
}

h3 {
  margin: 0.875rem 0;
  font-weight: bolder;
  font-size: 1.3125rem;
}

h4 {
  margin: 0.875rem 0;
  font-weight: normal;
  font-style: italic;
  margin: 0;
  font-size: 1.3125rem;
}

p {
  margin: 0.875rem 0 1.75rem;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: #000;
}

hr {
  border-color: transparent;
}

.hide-print {
  display: none;
}

.page--index ul::after {
  content: "";
  flex: auto;
}

.page--index ul li {
  flex: 0 0 25% !important;
}

/**
 * VS theme by Andrew Lock (https://andrewlock.net)
 * Inspired by Visual Studio syntax coloring
 */
code[class*=language-],
pre[class*=language-] {
  color: #393A34;
  font-family: "Consolas", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  font-size: 0.95em;
  line-height: 1.2em;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre[class*=language-]::-moz-selection, pre[class*=language-] ::-moz-selection,
code[class*=language-]::-moz-selection, code[class*=language-] ::-moz-selection {
  background: #C1DEF1;
}

pre[class*=language-]::selection, pre[class*=language-] ::selection,
code[class*=language-]::selection, code[class*=language-] ::selection {
  background: #C1DEF1;
}

/* Code blocks */
pre[class*=language-] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
  border: 1px solid #dddddd;
  background-color: white;
}

/* Inline code */
:not(pre) > code[class*=language-] {
  padding: 0.2em;
  padding-top: 1px;
  padding-bottom: 1px;
  background: #f8f8f8;
  border: 1px solid #dddddd;
}

.token.comment,
.token.doctype {
  color: #008000;
  font-style: italic;
}

.token.string {
  color: #A31515;
}

.token.punctuation,
.token.operator {
  color: #393A34;
  /* no highlight */
}

.token.url,
.token.number,
.token.boolean,
.token.variable {
  color: #36acaa;
}


.token.keyword,
.token.attr-value,
.language-json .token.boolean,
.language-json .token.number,
code[class*=language-css] {
  color: #0000ff;
}

.token.function {
  color: #393A34;
}

.token.selector {
  color: #00009f;
}

.token.important {
  font-weight: bold;
}

.token.class-name,
.language-json .token.property {
  color: #2B91AF;
}

.token.tag,
.token.selector {
  color: #800000;
}

.token.attr-name,
.token.property,
.token.regex {
  color: #ff0000;
}

/* overrides color-values for the Line Numbers plugin
 * http://prismjs.com/plugins/line-numbers/
 */

/* overrides color-values for the Line Highlight plugin
* http://prismjs.com/plugins/line-highlight/
*/